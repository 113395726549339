// extracted by mini-css-extract-plugin
export var active = "linksPage-module--active--ffa21";
export var head = "linksPage-module--head--853e6";
export var hidden = "linksPage-module--hidden--13529";
export var linksDiv = "linksPage-module--linksDiv--88f29";
export var linksPage = "linksPage-module--linksPage--2a808";
export var logoBottom = "linksPage-module--logoBottom--3fd70";
export var meCircle = "linksPage-module--meCircle--3f257";
export var share = "linksPage-module--share--3104d";
export var sosmed = "linksPage-module--sosmed--f1116";
export var topBar = "linksPage-module--topBar--800dc";